import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getDesignEstimate } from '../../store/layout/designEstimate';
import { convertToFormData } from '../ConvertToFormData';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import RequestForInvoice from "../reusable/RequestForInvoice";
import { handleDownload } from '../reusable/downloadFile';
// import DialogModal from '../reusable/DialogModal';
import Fade from '@mui/material/Fade';
import CancelIcon from "@mui/icons-material/Cancel";
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import otherFile from '../../assets/img/file-icon.png'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Popper from '@mui/material/Popper';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function DesignEstimate() {
  localStorage.removeItem("isRevisedViewClick");
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([]);
  // const formId = useSelector((state) => state?.inputDrawingReducer?.result?.results?.data[0]);
  const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);

  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = React.useState();
  const [openPopper, setOpenPopper] = useState(null);

  const handleClickPopper = (newPlacement, index) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper((prev) => (prev === index ? null : index)); // Toggle popper for this row
    setPlacement(newPlacement);
  };

    const handleCloses = () => {
      setOpen(false);
      setOpenPopper(null); 
      setAnchorEl(null);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const designEstimate = async () => {
    const formD = {
      'form_id': formId.id
    }
    const data = convertToFormData(formD);
    try {
      const response = await dispatch(getDesignEstimate(data));
      setData(response?.payload?.data);
    } catch (error) {
    }
  }

  useEffect(() => {
    designEstimate();
  }, []);

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Typography variant="h6" className="fs-17  fw-400">Design Estimates</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
            <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ background: "#85949F !important" }}>Estimate Type</StyledTableCell>
                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Date</StyledTableCell>
                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Payment Status</StyledTableCell>
                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Invoice</StyledTableCell>
                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row, index) => (
                    <StyledTableRow key={row.estimate}>
                      <StyledTableCell component="th" scope="row">
                        {row?.estimate_type}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.created_at ? convertToDateFormatYearWithTwoDigits(row.created_at) : "-"}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.payment_status == false ? (

                          <Button className="notdoneBtn"> Not Done</Button>
                        ) : (
                          <Button className="doneBtn"> Done</Button>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">    {row.invoice == null ? (
                        <Button className="notdoneBtn" onClick={() => handleOpen()}>Request</Button>
                      ) : (
                        <Button className="DownloadBtn" onClick={handleClickPopper('right', index)}>View</Button>

                      )}
                        <>
                          <Popper
                            id={`popper-${index}`}
                            open={openPopper === index}
                            anchorEl={anchorEl}
                            transition
                            placement={placement}
                          //  id={id} open={open} anchorEl={anchorEl} transition placement={placement}
                          >

                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps} timeout={350}>

                                <Box
                                  sx={{
                                    border: '1px solid',
                                    borderColor: 'grey.300',
                                    boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                    p: 1,
                                    bgcolor: 'background.paper',
                                    borderRadius: 0,
                                    position: 'relative',
                                    width: 320,
                                    minHeight: 150,



                                  }}
                                >
                                  <CancelIcon sx={{ position: "absolute", right: "5px", top: "5px", cursor: "pointer" }} onClick={handleCloses} />



                                  <Box >
                                    <Box className="m-auto p-relative">
                                      <Tooltip title={row.invoice} placement="top" className="uploadedfilename">
                                        <img width={30} height={30} src={otherFile} className="my-auto" alt="Other File" style={{ marginRight: "3px" }} />
                                        <Typography>Invoice</Typography>
                                        <DownloadForOfflineRoundedIcon
                                          className="my-auto"
                                          onClick={() => handleDownload(new Date() + 'invoice', row?.invoice)}
                                        />
                                        <FullscreenIcon
                                          className="my-auto"
                                          onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${row?.invoice}`)}
                                        />
                                      </Tooltip>
                                    </Box>
                                  </Box>

                                </Box>
                              </Fade>
                            )}
                          </Popper></>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button className="DownloadBtn" onClick={() => handleDownload(new Date() + 'schematic_cad', row?.estimate)}>Download</Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
      <RequestForInvoice open={open} handleClose={handleClose} />
      {/* <DialogModal/> */}
    </>
  );
}