import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import mapIcon from "../../assets/svg/mapIcon.svg";
import closeIcon from "../../assets/img/closeIcon.png";
import stepInactive from "../../assets/img/stepInactive.png";
import { ReactComponent as PurposeIcon } from "../../assets/svg/purposeIcon.svg";
import { ConvertDateString } from "../../utils/dateFormater";

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: theme.palette.grey[300],
    borderTopWidth: 2,
  },
}));

function CustomStepIcon() {
  return (
    <Box
      component="span"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "green",
      }}
    >
      <CheckCircleIcon sx={{ fontSize: 25 }} />
    </Box>
  );
}
function DialogHeader({ orderList }) {
  return (
    <Grid
      container
      spacing={1}
      sx={{ padding: "0 0 10px", alignItems: "center" }}
    >
      <Grid item sm={12} md={6}>
        <Box className="d-flex" sx={{ flexDirection: "column" }}>
          <Typography variant="h5" className="fs-15 fw-600 my-auto text-blue">
            {orderList?.main_order_id}
          </Typography>
          <Box className="d-flex gap-5 mt-10">
            <Box className="d-flex justify-center" sx={{ width: 15 }}>
              <img src={mapIcon} alt="" style={{ width: 15, height: 20 }} />
            </Box>
            <Typography variant="h5" className="fs-13 fw-400 my-auto">
              {orderList?.ordered_by_user_name}
            </Typography>
          </Box>
          <Box className="d-flex gap-5 mt-10">
            <Box className="d-flex justify-center" sx={{ width: 15 }}>
              <PurposeIcon />
            </Box>
            <Typography variant="h5" className="fs-13 fw-400 my-auto">
              Purpose : {orderList?.purpose}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <Card
          sx={{
            backgroundColor: "#1C1C1C",
            padding: "10px 25px",
            borderRadius: "5px",
          }}
        >
          <CardContent sx={{ p: "0" }}>
            <Typography gutterBottom sx={{ color: "#ABA9A9", fontSize: 13 }}>
              Shipped To
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{
                color: "#ffffff",
                fontSize: 15,
                fontWeight: 600,
                mb: 1,
              }}
            >
              {'Noida'}
            </Typography>
            <Typography sx={{ color: "#ffffff", fontSize: 13 }}>
              {'Allied Kia - Noida  Plot No. A, 10, A Block, Sector 6,201301'}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function SubOrderHeader({ orderList }) {
    const isDelivered = orderList?.sub_order_status === "Order Delivered";
    return (<>
      <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="#97AFC1"
      p={2}
      borderRadius={2}
    >
      <Typography
        variant="h6"
        color="white"
        sx={{ fontSize: 15, fontWeight: 600 }}
      >
        {orderList?.category} | {orderList?.sub_order_id}
      </Typography>
      <Box
        className="order-status"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          color: isDelivered ? "#15640f" : "#05141f",
          backgroundColor: isDelivered ? "#d5ffd4" : "#fff7e2",
          borderColor: isDelivered ? "#d5ffd4" : "#e9c056",
        }}
      >
        <Typography variant="h5" sx={{ fontSize: 11, fontWeight: 400 }}>
          {orderList?.sub_order_status}
        </Typography>
        {isDelivered && <CheckCircleIcon />}
      </Box>
    </Box>
    </>
    )
}

function OrderStatusSteps({ steps }) {
  return (
    <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
      <Paper sx={{ py: "10px" }}>
        <Stepper alternativeLabel connector={<CustomConnector />}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: 11,
                    fontWeight: "600 !important",
                    color: "#05141F !important",
                    minHeight: "32px",
                  }}
                >
                  {step.status}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 11,
                    fontWeight: 300,
                    lineHeight: "1",
                    color: "#05141F !important",
                  }}
                >
                  {ConvertDateString(step.date)}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
    </Box>
  );
}
function OrderDetailItem({ name, size, quantity, price, product_snap }) {
  return (
    <Box>
      <Box display="flex" alignItems="center" py={1}>
        <Box
          component="img"
          src={`${process.env.REACT_APP_BASE_URL}${product_snap}`}
          alt={name}
          sx={{ width: 50, height: 50, borderRadius: 1, mr: 2 }}
        />
        <Box flex="1">
          <Typography variant="body1">{name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {size}
          </Typography>
        </Box>
        <Box textAlign="right" display="flex" alignItems="center" gap="10px">
          <Typography variant="body2">Qtn: {quantity}</Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            width="130px"
            color="#05141F"
          >
            {price}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
function OrderSummary({ items, total }) {
  return (
    <Box mt={2} p={2}>
      {items.map((item, index) => (
        <OrderDetailItem key={index} {...item} />
      ))}
      <Divider sx={{ my: 2 }} />
      <Box display="flex" justifyContent="flex-end" fontWeight="bold">
        <Typography variant="body1">Total:</Typography>
        <Typography
          variant="body1"
          ml={1}
          fontWeight="600 !important"
          color="#05141F"
        >
          {total}
        </Typography>
      </Box>
    </Box>
  );
}

export default function OrderStatusModal({ open, onClose, selectedOrder }) {
  const [orderList, setOrderlist] = useState([]); 
  useEffect(() => {
    setOrderlist(selectedOrder);
  }, [selectedOrder]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          margin: 0,
          position: "fixed",
          right: 0,
          top: 0,
          height: "100%",
          maxHeight: "100%",
          width: "600px",
          borderRadius: "0px",
          overflow: "hidden",
          padding: "20px",
        },
      }}
    >
      <DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <img src={closeIcon} alt="Close" />
        </IconButton>
      </DialogTitle>
      <DialogHeader orderList={orderList} />
      <Divider
        className=""
        sx={{ borderColor: "#DCE9F4", mt: "10px", mb: "10px" }}
      />
      <DialogContent
        sx={{ overflowY: "auto", height: "100%", paddingInline: 0 }}
      >
        <DialogContentText color="#05141F !important">
          {orderList?.order_items?.map((subOrder, index) => (
            <Box key={index} sx={{ borderRadius: 2,maxWidth: 600,mx: "auto",backgroundColor: "#F6FBFF",mb: "10px"}}>
              <SubOrderHeader orderList={subOrder}/>
              <OrderStatusSteps steps={subOrder?.order_status} />
              <OrderSummary items={[subOrder]} total={subOrder?.quantity*subOrder?.price} />
            </Box>
          ))}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
