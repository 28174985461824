import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Box, Tooltip, Typography } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useDispatch, useSelector } from 'react-redux';
import { handleDownload } from './downloadFile';
import pdf from '../../assets/img/pdf.png'
import { viewPaymentApi } from '../../store/viewOrder/viewPayment';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';

export default function Viewpayment({ open, handleClose, Invoice }) {
    const dispatch = useDispatch();
    const viewPayment = useSelector((state) => state?.viewPaymentReducer?.result);
    useEffect(() => {
        dispatch(viewPaymentApi())
    }, [])

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Box><strong> View Invoice</strong></Box>
                    </Box>
                    <Divider sx={{ width: '100%', mt: 1, mb: 2 }} />
                </DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center" spacing={2}>
                        {Invoice?.map((data, index) => (
                            <Box sx={{ width: "250px" }} key={index}>
                                <Box className="upload-box bg-white text-center">
                                    <Box className="m-auto p-relative">
                                        <Tooltip
                                            title={data?.upload_invoice?.split('/')?.pop()}
                                            placement="top" className="uploadedfilename">
                                            <img width={40} height={40} src={pdf} className="my-auto" alt="PDF File" />
                                            <Typography>PDF File</Typography>
                                            <DownloadForOfflineRoundedIcon
                                                className="my-auto"
                                                onClick={() => handleDownload(new Date() + 'schematic_cad', data?.upload_invoice)}
                                            />
                                            <FullscreenIcon
                                                className="my-auto"
                                                    onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${data?.upload_invoice}`)}

                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>))}
                    </Grid>
                </DialogContent>
                <Divider sx={{ width: '100%', mb: 2 }} />
                <DialogActions
                    sx={{ display: 'flex', justifyContent: 'center', gap: 2, p: 2 }}
                >
                    <Button
                        onClick={handleClose}
                        color='error'
                        sx={{ backgroundColor: '#f44336', color: '#fff', '&:hover': { backgroundColor: '#d32f2f' } }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
