import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Body from "../../components/Body";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UploadPaymentRecipt from "../reusable/UploadPaymentRecipt";
import bookmarkdetail from "../../assets/svg/bookmarkdetail.svg"
import Viewpayment from "../reusable/ViewPayment";
import { formatDateString } from "../ConvertToFormData";
import { viewOrderHistory } from "../../store/viewOrder/viewOrderHistory";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { selectOutletApi } from "../../store/viewOrder/selectOutlet";
import boxsvg from '../../assets/svg/box.svg';

const ViewBillAndInvoice = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const viewOrder = useSelector((state) => state?.selectOutletReducer?.result);
    const order = location.state ?? { selectedOrder: JSON.parse(localStorage.getItem("selectedOrder"))};
    const matchedOrder = viewOrder?.find((x) => x.id === order?.selectedOrder?.id);
   
    const [paymentDate, setPaymentDate] = useState(matchedOrder?.payments[0]?.payment_on?.split("T")[0] || "N/A");
    const selectedCluster = location.state?.selectedCluster || localStorage.getItem("selectedCluster");

     const handleUpdatePaymentDate = (newDate) => {
        setPaymentDate(newDate);
    };

    const handleBack = () => {  
        navigate('/view-order', { state: { selectedCluster } });
   
    };

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleOpenModal() {
        setOpenModal(true);
    }

    function handleCloseModal() {
        setOpenModal(false);
    }


 

    const calculateTotal = (orderItems) => {
        return orderItems.reduce((total, item) => {
            if (item.quantity && item.price) {
                return total + (item.quantity * item.price);
            }
            return total;
        }, 0);
    };
  
      
    return <div>
        <Body>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                   <KeyboardArrowLeftIcon style={{cursor:"pointer"}} onClick={handleBack}/>
                </Grid>
                <Stack
                    direction="row"
                    spacing={10}
                    sx={{ my: 2 }}
                    justifyContent="space-between"
                    alignItems={"center"}
                >
                    <Stack direction="row" alignItems="center">
                        <img src={boxsvg} alt="Meter" />
                        <Typography
                            color={"#05141F"}
                            fontSize={"17px"}
                            fontWeight={400}
                            marginInlineStart={"10px"}
                            fontFamily={"'KiaSignatureRegular' !important"}
                        >
                            View Bill and Invoice
                        </Typography>
                    </Stack>
                </Stack>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card className="order-card">
                        <CardHeader
                            avatar={
                                <Box sx={{ borderRight: "1px solid #D4E0EC !important", paddingRight: "10px" }}>
                                    <Typography variant="h5" className="fs-15 fw-400">

                                        {matchedOrder?.ordered_by_user_name}
                                    </Typography>
                                    <Typography varient="h5" className="fs-15 fw-400">

                                        {matchedOrder?.cluster_name}
                                    </Typography>
                                </Box>
                            }
                            // action={
                            //     <Box>
                            //         <Button className="white-btn" sx={{ padding: "10px 4px 10px 4px !important", minWidth: "110px !important", fontSize: "13px !important", gap: "5px", textTransform: "capitalize" }}>
                            //         Raise a Query
                            //         </Button>

                            //     </Box>
                            // }
                            title="Main Order ID"
                            subheader={matchedOrder?.main_order_id}
                        />
                        <CardContent>
                            <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: "none !important" }}>
                                <TableContainer className="solidtable" sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">

                                        <TableBody>

                                            <TableRow >
                                                <TableCell className="cstm-tablecell1">
                                                    Order Date
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    {matchedOrder?.order_date.split("T")[0]
                                                    }
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Order Total
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    ₹ {calculateTotal(matchedOrder?.order_items)} ({((matchedOrder?.order_items)?.length)} Material)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="cstm-tablecell1">
                                                    Order Finalized Date
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    N/A
                                                </TableCell>
                                                <TableCell className="cstm-tablecell1">
                                                    Payment on
                                                </TableCell>
                                      <TableCell className="cstm-tablecell2" >
                                      {paymentDate}
                                           {/* {matchedOrder?.payments[0]?.payment_on.split("T")[0]} */}
                                         </TableCell>

                                            </TableRow>
                                            <TableRow >
                                                <TableCell className="cstm-tablecell1">
                                                    Payment
                                                </TableCell>
                                                 <TableCell className="cstm-tablecell2">
                                                       {matchedOrder?.payments[0]?.payment_completed ? 'Completed' : 'Incomplete'}
                                                    </TableCell>
                                                {/* <TableCell className="cstm-tablecell1">
                                                    Expected Delivery Date
                                                </TableCell>
                                                <TableCell className="cstm-tablecell2">
                                                    N/A
                                                </TableCell> */}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </CardContent>
                        <CardActions disableSpacing className="gap-10">
                            <Button className="orange-btn fs-11" sx={{ padding: "10px 18px 10px 18px !important", minWidth: "110px !important", fontSize: "13px !important", gap: "5px", textTransform: "capitalize" }} onClick={() => handleOpen()}>
                                <img height={15} src={bookmarkdetail} /> Upload payment receipt
                            </Button>
                            <Button
                                className="green-btn fs-11"
                           
                                sx={{
                                    padding: "10px 18px 10px 18px !important",
                                    minWidth: "110px !important",
                                    fontSize: "13px !important",
                                    gap: "5px",
                                    textTransform: "capitalize",
                                    backgroundColor: matchedOrder?.invoices?.length ? 'orange' : '',
                                    color: matchedOrder?.invoices?.length ? '#000' : '',
                                }}

                                onClick={handleOpenModal}
                                disabled={!matchedOrder?.invoices?.length}
                            >
                                {/* <img src={bookmarkdetail} alt="View Invoice" /> View Invoice */}
                               View Invoice
                            </Button>
                        </CardActions>

                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>

                </Grid>

            </Grid>
        </Body>
        <UploadPaymentRecipt open={open} handleClose={handleClose} orderId={order?.selectedOrder?.id}  handleUpdatePaymentDate={handleUpdatePaymentDate}/>
        <Viewpayment open={openModal} handleClose={handleCloseModal} Invoice={order?.selectedOrder?.invoices} />
    </div>;
};

export default ViewBillAndInvoice