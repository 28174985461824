import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import FilterStyles from "../../styles/filters.module.scss";
import TableRow from '@mui/material/TableRow';
import ModalCss from "../../styles/modal.module.scss";
import Paper from '@mui/material/Paper';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControl, Grid, IconButton, Modal, Stack, Typography } from "@mui/material";
import Body from "../../components/Body";
import { useNavigate } from 'react-router-dom';
import '../../styles/popup.scss';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function AddMoreMaterial({ open, handleClose, result, selectedItems, handleCheckboxChange }) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState([]);

    const handleCheckbox = (id) => {
        setSelectedRows((prevSelected) => {
            const updatedSelectedRows = [...prevSelected];
            if (updatedSelectedRows.includes(id)) {
                const index = updatedSelectedRows.indexOf(id);
                updatedSelectedRows.splice(index, 1);
            } else {
                updatedSelectedRows.push(id);
            }
            return updatedSelectedRows;
        });
     
    };
    const [materials, setMaterials] = useState([]);
    useEffect(() => {
        const filteredData = result?.filter((item) => !selectedItems?.includes(item?.id));
        setMaterials(filteredData);
    },[selectedItems])

    const setSelectedRowsData = () =>{
        selectedRows.forEach(id => handleCheckboxChange(id));
        handleClose()
        
    }
    return (
        <Modal open={open} onClose={handleClose} className={`${ModalCss?.customModal} ${ModalCss?.customModalBig} `}>
            <Card className={`modal popup ${ModalCss?.customModal_md}`}>
            <CardHeader className="popup-header-rev pt-0 pb-0"
                        action={
                            <IconButton onClick={handleClose}>
                                <HighlightOffIcon />
                            </IconButton>
                        }
                        title={
                            <Box>
                            </Box>
                        }
                    >

                    </CardHeader>

                {/* <Stack
                    direction="row"
                    spacing={10}
                    sx={{ my: 2 }}
                    justifyContent="space-between"
                    alignItems={"center"}
                >
                    <Stack direction="row" alignItems="center">
                        <img src={""} alt="Meter" />


                    </Stack>
                </Stack> */}
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant='h4' className='fs-23 fw-600 text-center mb-20' >
                                You haven't selected these items for order,<br></br>plese confirm once and thne proceed further to place the order
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                
                                        <TableRow>
                                        <StyledTableCell sx={{ background: "#85949F !important" }}>
                                                <Checkbox
                                                    // checked={selectedRows.length === materials?.length}
                                                    // onChange={() =>
                                                    //     setSelectedRows(selectedRows.length === materials?.length ? [] : materials?.map((row) => row.id))
                                                    // }
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }}>Vendor/Manufacturer</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Product Description</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Snap</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Kia Specification</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Supplier Specification</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">In Stock</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Price</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Unit</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Estimated Date by</StyledTableCell>
                                      
                                        </TableRow>  
                                    </TableHead>
                                    <TableBody>
                                    {materials?.map((row) => 
                                          <StyledTableRow key={row.id}>
                                          <StyledTableCell>
                                              <Checkbox
                                                  checked={selectedRows.includes(row.id)}
                                                  onChange={() => handleCheckboxChange(row.id)}
                                              />
                                          </StyledTableCell>
                                          <StyledTableCell component="th" scope="row">
                                              {row?.category_name}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                              {row?.product_description}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                              <img width={50} height={50} src={`https://kiastageapi.dev.vinove.com${row?.snap}`} />
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                              {row?.kia_specification}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">-</StyledTableCell>
                                          <StyledTableCell align="center">-</StyledTableCell>
                                          <StyledTableCell align="center">
                                              {row?.vendor_price}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                              {row?.unit_of_measurement}
                                          </StyledTableCell>
                                          <StyledTableCell align="center">-</StyledTableCell>
                                      </StyledTableRow>
                                    )}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>
                </CardContent>
                <CardActions disableSpacing>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="flex-wrap justify-content-between">
                            <Button className="back-btn fs-17" onClick={() => navigate(-1)}>Back</Button>
                            <Box className="gap-5 flex-wrap">
                                <Button className="white-btn" onClick={() => navigate("/order/place-Order")} >Not Required</Button>
                                <Button className="black-btn" onClick={()=> setSelectedRowsData()}>Proceed</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Modal>
    )
}
export default AddMoreMaterial